import React from 'react'

import Layout from '../../components/Layout'

export default class SalesPage extends React.Component {
    render() {
        return (
            <Layout>
                <div
                    className="full-width-image-container margin-top-0"
                    style={{
                        backgroundImage: `url('/img/ac-main.jpg')`,
                    }}
                >

                    <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen" style={{
                        boxShadow: 'rgb(51, 153, 51) 0.5rem 0px 0px, rgb(51, 153, 51) -0.5rem 0px 0px',
                        backgroundColor: 'rgb(51, 153, 51)',
                        color: 'white',
                        lineHeight: '1',
                        padding: '0.25em'
                    }} >
                        Sales
                    </h1>
                </div>
                <section>
                    <div className="container">
                        <div className="content">
                            <div className="columns">
                                <div className="column is-12 main-head">
                                    We provides Sales and Services for all Air conditioners of Godrej brand. Contact Us at +91 9964100052 to know get a list of all products and to know more about the price of each of the models. Some of the popular models are listed below
                                </div>
                            </div>
                            <br />
                            <div className="columns is-multiline">

                                <div className="is-parent column is-6" key="1">
                                    <article
                                        className="blog-list-item tile is-child box notification"
                                    >
                                        <span className="prod-name">Godrej 1.5 Ton 5 Star Inverter Split AC (Copper, Anti Bacterial Filter, 2020 Model, GIC 18HTC5-WTA, White)</span>
                                        <br />
                                        <ul>
                                            <li>Spilt Ac with inverter compressor: variable speed compressor which adjusts power depending on heat load. It is most energy efficient and has lowest-noise operation.</li>
                                        </ul>

                                    </article>

                                </div>

                                <div className="is-parent column is-6" key="1">
                                    <article
                                        className="blog-list-item tile is-child box notification"
                                    >
                                        <span className="prod-name">Godrej 1.5 Ton 3 Star Inverter Split AC (Copper, Anti-bacterial filter filter, 2021 Model, AC 1.5T GIC 18NTC3-WUA, White)</span>
                                        <br />
                                        <ul>
                                            <li>Spilt Ac with inverter compressor: variable speed compressor which adjusts power depending on heat load. It is most energy efficient and has lowest-noise operation.</li>
                                        </ul>
                                    </article>

                                </div>

                                <div className="is-parent column is-6" key="1">
                                    <article
                                        className="blog-list-item tile is-child box notification"
                                    >
                                        <span className="prod-name">Godrej 2 Ton 5 Star Inverter Split AC (Copper, Dust Filter, 2020 Model, GIC 24ETC5-WTA Split 5S White)</span>
                                        <br />
                                        <ul>
                                            <li>Spilt Ac with inverter compressor: variable speed compressor which adjusts power depending on heat load. It is most energy efficient and has lowest-noise operation.</li>
                                        </ul>
                                    </article>

                                </div>

                                <div className="is-parent column is-6" key="1">
                                    <article
                                        className="blog-list-item tile is-child box notification"
                                    >
                                        <span className="prod-name">Godrej 1 Ton 3 Star Inverter Split AC (Copper, Anti-bacterial filter filter, AC 1T GIC 12TTC3-WUA Split, White)</span>
                                        <br />
                                        <ul>
                                            <li>Spilt Ac with inverter compressor: variable speed compressor which adjusts power depending on heat load. It is most energy efficient and has lowest-noise operation.</li>
                                        </ul>
                                    </article>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <div className="section"></div>
            </Layout>
        )
    }
}